export const sessionKeys = {
  contactForm: "contact_form",
  remindForm: "remind_form",
  joinForm: "join_form",
  orderForm: "order_form",
  changeForm: "change_form"
}

export const errorMessageTemplates = {
  noInput: "が入力されていません",
  typo: "の入力に誤りがあります",
  inconsistency: "が一致していません"
}