import { post } from './api'

const isBrowser = () => typeof window !== "undefined"

export const getFormValues = (key, ...ignoreProps) => {
  if (isBrowser() && window.sessionStorage.getItem(key)) {
    var ret = JSON.parse(window.sessionStorage.getItem(key))
    ignoreProps.forEach(p => {
      delete ret[p]
    })
    return ret
  }
  return {}
}

export const setFormValues = (key, values) => {
  window.sessionStorage.setItem(key, JSON.stringify(values))
}

export const removeFormValues = (key) => {
  setFormValues(key, {})
}

export const postForm = (url, postData, callback = null) => {
  return post(url, postData, callback)
}