import React from "react"
import { Link, navigate } from 'gatsby'
import { sessionKeys } from "../services/const"
import { getFormValues, postForm } from '../services/form'
import Seo from '../components/seo'

class RemindNumberConfirm extends React.Component {

  handleSubmit = event => {
    event.preventDefault()
    const postData = getFormValues(sessionKeys.remindForm)
    if (postForm(process.env.API_GATEWAY_BASE_URL + "remind_number", postData)) {
      navigate('/remindNumberComplete')
    }
    else {
      console.log('Server Error');
    }
  }

  handleBack = event => {
    event.preventDefault()
    navigate('/remindNumber')
  }

  render = () => {
    const remindForm = getFormValues(sessionKeys.remindForm)

    return (
      <>
        <Seo title="会員番号のお問合せ" />
        <div id="pageTitle">
          <h1>
            会員番号のお問合せ
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>会員番号のお問合せ</li>
          </ul>
        </div>
        <div id="main">
          <div className="contents">

            <section className="contents_block">
              <p>入力内容をご確認の上、送信してください。</p>
            </section>

            <section className="contents_block narrow mb50">
              <form name="" onSubmit={this.handleSubmit} >

                <div className="form_block">
                  <div className="form_head">
                    お名前
                  </div>
                  <div className="form_body">
                    {remindForm.LastNameKanji} {remindForm.FirstNameKanji}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    お名前（カナ）
                  </div>
                  <div className="form_body">
                    {remindForm.LastNameKatakana} {remindForm.FirstNameKatakana}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    生年月日
                  </div>
                  <div className="form_body">
                    {remindForm.BirthYear}年{remindForm.BirthMonth}月{remindForm.BirthDay}日
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    お電話番号
                  </div>
                  <div className="form_body">
                    {remindForm.TelephoneNumber}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    メールアドレス
                  </div>
                  <div className="form_body">
                    {remindForm.MailAddress}
                  </div>
                </div>


                <div className="aC"><input type="submit" value="この内容で送信する" className="btn_rounded" /></div>
                <div className="aC mb50"><input type="button" value="戻る" className="btn_rounded keyColor08b" onClick={this.handleBack} /></div>
              </form>
            </section>

          </div>
        </div>


      </>
    )
  }
}

export default RemindNumberConfirm